import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="pt-8 px-24">
      <h2 className="text-4xl w-full text-center">Privacy Policy</h2>
      <p>Last Updated: 20-3-2024</p>
        <p className="text-2xl w-full text-center">IMPORTANT: 
        Google Privacy Policy: http://www.google.com/policies/privacy<br/>
        To revoke our access to your Google account at anytime, go to https://security.google.com/settings/security/permissions and search for "ytunsuuber".
        </p>
        this app stores the following cookies on users browser:
        <li>User system theme(light mode, dark mode)</li>
        <li>Google Oauth Provider Token</li>
        
        
      <p>
        This Privacy Policy describes how Unsuuber (referred to as "we," "us," or "our") collects, uses, and discloses information when you use the Unsuuber web application (referred to as the "Service"). By accessing or using the Service, you agree to be bound by this Privacy Policy.
      </p>
      <h3>Unsuuber's use and transfer to any other app of information received from Google APIs will adhere to Google API Services User Data Policy, including the Limited Use requirements.</h3>
      <h3>1. Information We Collect</h3>
      <p>
        1.1. <strong>Google Account Information:</strong> When you sign in to the Service using your Google account, we collect and store your Google account information, including your email address and basic profile information.
        <br />
        1.2. <strong>Usage Information:</strong> We may collect certain information about your use of the Service, such as the actions you take within the application. However, we do not collect any personally identifiable information through these actions.
      </p>
      <h3>2. Use of Information</h3>
      <p>
        2.1. <strong>Google Account Information:</strong> We use your Google account information solely for the purpose of authenticating your identity and providing access to the Service. We do not use this information for any other purpose or share it with any third parties.
        <br />
        2.2. <strong>Usage Information:</strong> We may use usage information to improve the functionality and user experience of the Service. However, this information is aggregated and anonymized, and it is not linked to any personally identifiable information.
      </p>
      <h3>3. Disclosure of Information</h3>
      <p>We do not disclose your Google account information or usage information to any third parties except as required by law or to comply with legal process.</p>
      <h3>4. Data Retention</h3>
      <p>We retain your Google account information only for as long as necessary to provide you with access to the Service and to comply with our legal obligations. We do not retain usage information beyond what is necessary for the purposes outlined in this Privacy Policy.</p>
      <h3>5. Security</h3>
      <p>We take reasonable measures to protect the security of your Google account information. However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security.</p>
      <h3>6. Third-Party Services</h3>
      <p>The Service utilizes the YouTube Data API to provide its functionality. Your use of the Service is subject to the YouTube API Services Terms of Service and Developer Policies.</p>
      <h3>7. Children's Privacy</h3>
      <p>The Service is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us so that we can take appropriate action.</p>
      <h3>8. Changes to Privacy Policy</h3>
      <p>We reserve the right to modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised policy on the Service. Your continued use of the Service after the posting of the revised policy constitutes your acceptance of the changes.</p>
      <h3>9. Contact Information</h3>
      <p>If you have any questions about this Privacy Policy, please contact us at unsubber@protonmail.com.</p>
    </div>
  );
};

export default PrivacyPolicy;
