import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../components/ui/card";
import { supabase } from "../lib/utils";
import google from "../google.png";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion";
import { Checkbox } from "../components/ui/checkbox";
import { CheckedState } from "@radix-ui/react-checkbox";
import GoogleSvg from "../components/svgs/Google";
import DemoSwipe from "../components/DemoSwipe";
import { ArrowDown } from "lucide-react";

function AccordionDemo() {
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="item-1">
        <AccordionTrigger>How does it work?</AccordionTrigger>
        <AccordionContent>
          You choose from a stack of subscriptions to choose which ones to keep
          and which ones to remove, a bit like swiping on dating apps.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-2">
        <AccordionTrigger>Where does the data come from?</AccordionTrigger>
        <AccordionContent>
          We use the offical Youtube API, we do not store any of your data, only
          your email that you used to sign in!
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-3">
        <AccordionTrigger>How much does it cost?</AccordionTrigger>
        <AccordionContent>
          This app is free and runs entirely on donations, if you want to
          support us, feel free to visit our{" "}
          <a
            className="underline"
            href="https://ko-fi.com/unsuuber"
            target="_blank"
            rel="noreferrer"
          >
            donation page!
          </a>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

const Signup = () => {
  const navigate = useNavigate();
  const [result, setResult] = React.useState("");
  useEffect(() => {
    supabase.auth.getUser().then(async (session) => {
      const id = session.data.user?.id;
      if (!id) {
        return;
      }

      const authProviderTokenFromLocalStorage = window.localStorage.getItem(
        "oauth_provider_token"
      );
      if (authProviderTokenFromLocalStorage === null) {
        return;
      } else {
        navigate("/dashboard");
      }
    });
  }, []);
  const onSubmit = async (event: any) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "57d90d29-b348-4259-9336-ba421fa28c55");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };
  const checkedRef = useRef<CheckedState | null>(null);

  supabase.auth.onAuthStateChange((event, session) => {
    if (session && session.provider_token) {
      console.log("session:", session.provider_token);
      window.localStorage.setItem(
        "oauth_provider_token",
        session.provider_token
      );
    }

    if (session && session.provider_refresh_token) {
      window.localStorage.setItem(
        "oauth_provider_refresh_token",
        session.provider_refresh_token
      );
    }

    if (event === "SIGNED_OUT") {
      window.localStorage.removeItem("oauth_provider_token");
      window.localStorage.removeItem("oauth_provider_refresh_token");
    }
  });

  return (
    <div className="flex flex-col items-center max-[768px]:gap-6">
      <div className="flex flex-col justify-center min-h-[100dvh] max-[768px]:h-full">
        <div className="w-full flex flex-row items-center justify-center ">
          <div className="w-[50%] max-[768px]:w-[90%] h-full flex flex-col items-center justify-center">
            <Card className="flex flex-col items-center justify-evenly gap-6 border-none py-4">
              <CardHeader className="flex flex-col items-center text-center">
                <h1 className="text-7xl">Unsuuber</h1>
                <h2 className="text-xl ">- Youtube Subscriptions Made Easy</h2>
              </CardHeader>
              <CardContent className="text-lg text-zinc-600 text-center flex flex-col gap-4 dark:text-zinc-200">
                <p>
                  Have hundreds of old YouTube Subscriptions that you will never
                  watch again? Us too. That's why we created Unsuuber, a tool
                  for you to quickly sift through your YouTube Subscriptions to
                  decide which ones to keep.
                </p>
                <CardFooter className="flex flex-col justify-evenly gap-4 w-full">
                  <h3 className="text-black">
                    Leave your Email and be notified when it's ready!
                  </h3>
                  <form onSubmit={onSubmit} className="w-full">
                    <input
                      name="userEmail"
                      required
                      type="email"
                      placeholder="Enter email here"
                      className="p-2 rounded-[8px] border-[2px] w-[50%] max-[768px]:w-full"
                    />
                  </form>
                  <span>{result}</span>
                </CardFooter>

                <div className="border p-6 rounded-xl">
                  <h2 className="text-zinc-700 text-3xl dark:text-zinc-50">
                    FAQ
                  </h2>
                  <AccordionDemo />
                </div>
              </CardContent>
              {/* <CardFooter className="flex flex-row max-[1300px]:flex-col justify-evenly max-[1300px]:gap-4 w-full">
                <Button
                  onClick={() => {
                    if (checkedRef.current === true) {
                      supabase.auth.signInWithOAuth({
                        provider: "google",
                        options: {
                          scopes:
                            "https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtubepartner",
                        },
                      });
                    } else {
                      return;
                    }
                  }}
                  className="flex flex-row h-12 max-[1300px]:w-full"
                >
                  <img src={google} className="h-12" alt="google logo" />
                  Sign In With Google
                </Button>
                <div className="items-top flex space-x-2">
                  <Checkbox
                    id="terms1"
                    onCheckedChange={(e) => {
                      checkedRef.current = e;
                      console.log(checkedRef);
                    }}
                  />
                  <div className="grid gap-1.5 leading-none">
                    <label
                      htmlFor="terms1"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      Accept terms and conditions
                    </label>
                    <p className="text-sm text-muted-foreground">
                      You agree to our{" "}
                      <Link to="/terms" target="_blank" className=" underline">
                        Terms of Service
                      </Link>{" "}
                      and{" "}
                      <Link to="/privacy" className="underline" target="_blank">
                        Privacy Policy
                      </Link>
                      .
                    </p>
                  </div>
                </div>
              </CardFooter> */}
            </Card>
          </div>
          <GoogleSvg />
        </div>
        <a
          className="absolute left-[50%] bottom-8 max-[768px]:bottom-4 max-[768px]:hidden"
          href="/#howItWorks"
        >
          <ArrowDown />
        </a>
      </div>

      <div
        className="flex flex-col items-center min-h-[100dvh] max-[768px]:h-full justify-center gap-6 max-[768px]:w-[90%] w-full"
        id="howItWorks"
      >
        <h2 className="text-4xl">How it works:</h2>
        <p>
          Your subscriptions will be showed in this format, you can then choose
          which ones to keep and which ones to unsubscribe from.
        </p>
        <DemoSwipe />
      </div>
      <footer className="mt-auto pb-3 border-t-slate-300  text-center">
        <span>Copyright © Unsuuber - All Rights Reserved.</span>
      </footer>
    </div>
  );
};

export default Signup;
