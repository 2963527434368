import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import Signup from "./Pages/Signup";
import TermsOfService from "./Pages/TOS";
import PrivacyPolicy from "./Pages/Privacy";
import { Analytics } from "@vercel/analytics/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Analytics />
    <GoogleOAuthProvider clientId="613508971633-n5hg626s1ok0mmtmrevtpt22p8shhkds.apps.googleusercontent.com">
      <BrowserRouter>
        <Routes>
          <Route path="/dashboard" element={<App />}></Route>
          <Route path="/" element={<Signup />}></Route>
          <Route path="/terms" element={<TermsOfService />}></Route>
          <Route path="/privacy" element={<PrivacyPolicy />}></Route>
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
