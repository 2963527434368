import { createClient } from "@supabase/supabase-js";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { Database } from "../types/supabase";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
const supabase = createClient<Database>(
  "https://ohaouoeknybutaqwkklr.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9oYW91b2VrbnlidXRhcXdra2xyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTA4NDk2NjIsImV4cCI6MjAyNjQyNTY2Mn0.urggr8E-Fop1eMT5ycrzpfZXzPAFXDp32rTXbqb6ZZk"
);

export { supabase };
